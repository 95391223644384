import React, {
	ChangeEvent,
	Dispatch,
	FormEvent,
	SetStateAction,
	useState,
} from "react"
import { Input, PrimaryBtn, Select, Textarea } from "components/library"
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from "utils/validation"
import { countries } from "countries-list"
import { ArrowRight } from "react-feather"
import { useChat } from "react-live-chat-loader"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"

const API_URL = `${process.env.GATSBY_BACKEND_API_V1}/_contact_us`
const COUNTRIES = Object.values(countries)

const initialData = {
	fname: "",
	lname: "",
	company: "",
	email: "",
	phone: "",
	country: "",
	country_code: "",
	message: "",
}

type P = {
	setModalOpen: Dispatch<SetStateAction<boolean>>
}

export const Form = ({ setModalOpen }: P) => {
	const [data, setData] = useState(initialData)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isEmailError, setIsEmailError] = useState(false)
	const [isPhoneNumberError, setIsPhoneNumberError] = useState(false)
	const [isCodeMissing, setIsCodeMissing] = useState(false)
	const [_, loadIntercom] = useChat()
	const { t } = useTranslation()

	function handleDataChange(
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	) {
		const { name, value } = e.target

		if (name === "email") setIsEmailError(false)
		if (name === "country_code") setIsCodeMissing(false)
		if (name === "phone") setIsPhoneNumberError(false)

		setData({
			...data,
			[name]: value,
		})
	}

	async function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault()
		if (!EMAIL_PATTERN.test(data.email)) return setIsEmailError(true)
		if (data.phone && !data.country_code) return setIsCodeMissing(true)
		if (data.phone && !PHONE_NUMBER_PATTERN.test(data.phone)) return setIsPhoneNumberError(true)

		setIsSubmitting(true)

		const phone = `+${data.country_code.split(",")[0]}-${data.phone}`

		const body = JSON.stringify({
			first_name: data.fname,
			last_name: data.lname,
			company_name: data.company,
			email: data.email,
			phone,
			country: data.country,
			message: data.message,
		})

		try {
			await (
				await fetch(API_URL, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body,
				})
			).json()
			setData(initialData)
			setModalOpen(true)
		} catch (err) {
			console.log({ err })
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<form
			onSubmit={handleSubmit}
			className="bg-white shadow-md rounded-lg w-full p-8 flex flex-col gap-8"
		>
			<div className="flex flex-col gap-3">
				<div className="flex flex-col lg:flex-row gap-3">
					<Input
						name="fname"
						value={data.fname}
						onChange={handleDataChange}
						className="w-full h-16"
						placeholder={`${t("common.words.first-name")}*`}
						required
					/>
					<Input
						name="lname"
						value={data.lname}
						onChange={handleDataChange}
						className="w-full h-16"
						placeholder={`${t("common.words.last-name")}*`}
						required
					/>
				</div>

				<Input
					name="company"
					value={data.company}
					onChange={handleDataChange}
					className="w-full h-16"
					placeholder={`${t("common.words.company")}/${t(
						"common.words.organization"
					)} (${t("common.words.optional")})`}
				/>

				<Input
					name="email"
					type="email"
					isError={isEmailError}
					value={data.email}
					onChange={handleDataChange}
					className="w-full h-16"
					placeholder={`${t("common.words.email")}*`}
					required
				/>

				<div className="flex gap-3 h-16">
					<Select
						name="country_code"
						className={`h-full w-32 ${data.country_code ? "" : "text-gray-400"
							} ${isCodeMissing ? "outline-red-400 outline outline-1" : ""}`}
						value={data.country_code}
						onChange={handleDataChange}
						placeholder={t("common.words.code")}
					>
						{COUNTRIES.map((country, key) => {
							return (
								<option key={key} value={country.phone}>
									{country.phone} ({country.name})
								</option>
							)
						})}
					</Select>
					<Input
						name="phone"
						type="tel"
						minLength={4}
						maxLength={14}
						validator={(value) =>
							(+value > 0 && +value < 1000000000000000) || value === ""
						}
						isError={isPhoneNumberError}
						value={data.phone}
						onChange={handleDataChange}
						className="w-full h-full"
						placeholder={`${t("common.words.phone")} (${t(
							"common.words.optional"
						)})`}
					/>
				</div>

				<Select
					name="country"
					className={`w-full h-16 ${data.country ? "" : "text-gray-400"}`}
					value={data.country}
					onChange={handleDataChange}
					placeholder={`${t("common.words.country")} ${t(
						"common.words.optional"
					)}`}
				>
					{COUNTRIES.map((country, key) => {
						return (
							<option key={key} value={country.name}>
								{country.name}
							</option>
						)
					})}
				</Select>

				<Textarea
					name="message"
					value={data.message}
					onChange={handleDataChange}
					className="h-60 min-h-[240px]"
					placeholder={t("contact-us.form.message")}
				/>
			</div>

			<div className="flex flex-col gap-4 items-center">
				<PrimaryBtn
					disabled={isSubmitting}
					type="submit"
					className="w-full py-5"
				>
					{isSubmitting ? t("common.cta.submitting") : t("common.cta.submit")}
				</PrimaryBtn>

				<div className="flex items-center gap-2">
					<span>{t("common.words.or")}</span>
					<button
						type="button"
						onClick={() => loadIntercom({ open: true })}
						className="flex items-center gap-1 text-theme-blue-hover"
					>
						<span>{t("common.words.chat-with-us")}</span>
						<ArrowRight size={16} />
					</button>
				</div>

				<span className="text-xs text-neutral-500 text-center">
					{/* By submitting this form, you have agreed to our <Link className='text-theme-blue' to="/privacy-policy/">Privacy Policy</Link> & <Link className='text-theme-blue' to="/terms-of-use">Terms of Use</Link>. */}
					<Trans
						i18nKey="common.messages.agree-to-tou-n-policy"
						components={{
							privacypolicy: <Link to="/privacy-policy/" />,
							termsofuse: <Link to="/terms-of-use/" />,
						}}
					/>
				</span>
			</div>
		</form>
	)
}
